import {defineStore} from 'pinia';
import {useI18n} from 'vue-i18n';
import type {
  TTypeCurrentRoom,
  ITypeRomm,
  IMeasureRoom,
  ICountBed,
  IStyleRoom,
} from '~/interfaces/design-studio';
import {useUserStore} from '~/modules/User/user.store';
import db from '~/assets/js/indexDB';
import {api, apiPayment} from '~/plugins/axios';

export const useDesignStudioStore = defineStore(
  'design-studio',
  () => {
    const user = useUserStore();
    const {locale} = useI18n();
    const router = useRouter();
    const userInfo = ref({
      name: '',
      surname: '',
      region: '',
      phone: '',
    });
    // all rooms added user
    const allRoom: Ref<TTypeCurrentRoom[]> = ref([]);
    // create new room and fill fields
    const currentRoom: Ref<TTypeCurrentRoom> = ref(null);
    // total price after calculate all rooms price
    const isDeisgnAllRoomCheckboxVisible = ref(false);
    const isDesignAllRoomCheckbox = ref(false);
    const isAgreeOfferCheckBox = ref(false);
    const createOrderLoader = ref(false);
    const orderInfo = ref({order: null, cost: null});

    const totalPriceDS = computed(() => {
      let totalPrice = 0;
      let totalPriceAfter = 0;
      let totalBathRoom = 0;
      let totalKitchen = 0;
      let livingRoom = 0;
      allRoom.value.forEach((room: TTypeCurrentRoom) => {
        if (room?.typeRoom?.type === 'bathRoom') {
          totalBathRoom += 1;
        } else if (room?.typeRoom?.type === 'kitchenRoom') {
          totalKitchen += 1;
        } else livingRoom += 1;
      });

      isDeisgnAllRoomCheckboxVisible.value =
        (totalBathRoom === 1 &&
          livingRoom === 1 &&
          totalKitchen === 0) ||
        (totalBathRoom >= 1 && totalKitchen >= 1 && livingRoom >= 1)
          ? true
          : false;

      switch (true) {
        case !isDesignAllRoomCheckbox.value ||
          !isDeisgnAllRoomCheckboxVisible.value:
          totalPrice +=
            1500 * totalBathRoom +
            1500 * totalKitchen +
            1500 * livingRoom;
          break;
        case totalBathRoom === 1 &&
          livingRoom === 1 &&
          totalKitchen === 0:
          totalPrice += 1500 * totalBathRoom + 1500 * livingRoom;
          break;
        default:
          totalPrice +=
            1500 * totalBathRoom +
            1500 * totalKitchen +
            (livingRoom > 3
              ? 1000 * 3 + (livingRoom - 3) * 1500
              : livingRoom * 1000);
          break;
      }

      // changes price after added current room
      switch (true) {
        case !currentRoom.value:
          break;
        case currentRoom.value?.typeRoom?.type === 'bathRoom':
          totalBathRoom += 1;
          break;
        case currentRoom.value?.typeRoom?.type === 'kitchenRoom':
          totalKitchen += 1;
          break;
        case currentRoom.value?.typeRoom?.type === 'livingRoom':
          livingRoom += 1;
          break;
      }

      if (isEditMode.value) {
        const editRoom = allRoom.value.find(
          (room) => room?.id === currentRoom.value?.id,
        );

        switch (true) {
          case !editRoom:
            break;
          case editRoom?.typeRoom?.type === 'bathRoom':
            totalBathRoom -= 1;
            break;
          case editRoom?.typeRoom?.type === 'kitchenRoom':
            totalKitchen -= 1;
            break;
          case editRoom?.typeRoom?.type === 'livingRoom':
            livingRoom -= 1;
            break;
        }
      }

      let isVisibleAfter =
        (totalBathRoom === 1 &&
          livingRoom === 1 &&
          totalKitchen === 0) ||
        (totalBathRoom >= 1 && totalKitchen >= 1 && livingRoom >= 1)
          ? true
          : false;

      switch (true) {
        case !isDesignAllRoomCheckbox.value || !isVisibleAfter:
          totalPriceAfter +=
            1500 * totalBathRoom +
            1500 * totalKitchen +
            1500 * livingRoom;
          break;
        case totalBathRoom === 1 &&
          livingRoom === 1 &&
          totalKitchen === 0:
          totalPriceAfter += 1500 * totalBathRoom + 1500 * livingRoom;
          break;
        default:
          totalPriceAfter +=
            1500 * totalBathRoom +
            1500 * totalKitchen +
            (livingRoom > 3
              ? 1000 * 3 + (livingRoom - 3) * 1500
              : livingRoom * 1000);
          break;
      }

      return {totalPrice, totalPriceAfter};
    });
    // allows to add a new room
    const isNeedFieldForAddNewRoom = ref(false);
    const isEditMode = ref(false);

    const goTo = (anchor: string) => {
      const el = document.querySelector(anchor);
      if (el) {
        el.scrollIntoView({behavior: 'smooth', block: 'nearest'});
      }
    };

    const btnCreateHandlerForLanding = () => {
      if (!user.isLoggedIn) {
        user.showLoginModal();
        user.specialLink = `/${locale.value}/design-studio/create`;
      } else {
        router.push(`/${locale.value}/design-studio/create`);
      }
    };

    const changeIsNeedFieldForAddNewRoom = () => {
      isEditMode.value = false;
      isNeedFieldForAddNewRoom.value =
        !isNeedFieldForAddNewRoom.value;
    };

    const addedNewRoom = () => {
      currentRoom.value = {
        ...currentRoom.value,
        id: (allRoom.value[allRoom.value.length - 1]?.id || 0) + 1,
      };
      if (currentRoom.value) {
        allRoom.value = [...allRoom.value, currentRoom.value];
      }
      setTimeout(() => {
        // goTo(`#card-room-${currentRoom.value?.id}`);
        currentRoom.value = null;
      }, 100);
      addRoomsToDB(currentRoom.value);
    };

    const addTypeForCurrentRoom = (typeRoom: ITypeRomm) => {
      currentRoom.value = {...currentRoom.value, typeRoom: typeRoom};
    };

    const addMeasureForCurrentRoom = (measureRoom: IMeasureRoom) => {
      currentRoom.value = {
        ...currentRoom.value,
        measureRoom: measureRoom,
      };
    };

    const addCountBedForCurrentRoom = (countBed: ICountBed) => {
      currentRoom.value = {
        ...currentRoom.value,
        countBed: countBed,
      };
    };

    const addStyleRoomForCurrentRoom = (styleRoom: IStyleRoom) => {
      currentRoom.value = {
        ...currentRoom.value,
        styleRoom: styleRoom,
      };
    };

    const addImagePlanForCurrentRoom = (file: any) => {
      currentRoom.value = {
        ...currentRoom.value,
        imagePlan: file,
      };
    };

    const deleteCurrentRoom = () => {
      currentRoom.value = null;
      changeIsNeedFieldForAddNewRoom();
    };

    const deleteRoomFromAllList = (selectRoom: TTypeCurrentRoom) => {
      allRoom.value = allRoom.value.filter(
        (room) => room?.id !== selectRoom?.id,
      );

      currentRoom.value = null;
      isNeedFieldForAddNewRoom.value = false;
      deleteRoomToDB(selectRoom);
    };

    const editCurrentRoomFromAllList = (room: TTypeCurrentRoom) => {
      isEditMode.value = true;
      currentRoom.value = room;
      isNeedFieldForAddNewRoom.value = true;
      setTimeout(() => {
        goTo('#create-room');
      }, 100);
    };

    const cancelEditFromAllRoomList = () => {
      currentRoom.value = null;
      isEditMode.value = false;
      isNeedFieldForAddNewRoom.value = false;

      setTimeout(() => {
        goTo('#room-create');
      }, 100);
    };

    const applyChangesFromEditAllRoomList = () => {
      const index = allRoom.value.findIndex(
        (room: TTypeCurrentRoom) =>
          room?.id === currentRoom.value?.id,
      );
      allRoom.value[index] = currentRoom.value;
      editRoomToDB(currentRoom.value);
      cancelEditFromAllRoomList();
    };

    const addedNewRoomFromPreviewRoom = () => {
      currentRoom.value = allRoom.value[allRoom.value.length - 1];
    };

    const addRoomsToDB = async (room: TTypeCurrentRoom) => {
      await db.saveRoom({
        id: room?.id,
        imagePlan: {...room?.imagePlan},
        measureRoom: {...room?.measureRoom},
        styleRoom: {...room?.styleRoom},
        typeRoom: {...room?.typeRoom},
        countBed: {...room?.countBed},
      });
    };

    const addUserInfoToLocal = async () => {
      localStorage.setItem(
        `ds-userInfo-${user.email}`,
        JSON.stringify({
          userInfo: userInfo.value,
          isAgreeOfferCheckBox: isAgreeOfferCheckBox.value,
        }),
      );
    };

    const setUserInfoNewFieldToLocale = async (
      field: string,
      value: any,
    ) => {
      const userInfoData = localStorage.getItem(
        `ds-userInfo-${user.email}`,
      );
      let userInfoDataParsed = {};
      if (userInfoData) {
        userInfoDataParsed = JSON.parse(userInfoData);
      }

      localStorage.setItem(
        `ds-userInfo-${user.email}`,
        JSON.stringify({
          ...userInfoDataParsed,
          [field]: value,
        }),
      );
    };

    const deleteUserInfoFieldToLocale = async (field: string) => {
      const userInfoData = localStorage.getItem(
        `ds-userInfo-${user.email}`,
      );
      let userInfoDataParsed: any = {};
      if (userInfoData) {
        userInfoDataParsed = JSON.parse(userInfoData);
      }

      if (userInfoDataParsed?.[field]) {
        delete userInfoDataParsed[field];
      }

      localStorage.setItem(
        `ds-userInfo-${user.email}`,
        JSON.stringify({
          ...userInfoDataParsed,
        }),
      );
    };

    const getUserInfoToLocal = async () => {
      const userInfoData = localStorage.getItem(
        `ds-userInfo-${user.email}`,
      );
      if (userInfoData) {
        const userInfoDataParsed = JSON.parse(userInfoData);
        userInfo.value = userInfoDataParsed?.userInfo;
        isAgreeOfferCheckBox.value =
          userInfoDataParsed.isAgreeOfferCheckBox;

        if (userInfoDataParsed?.orderInfo) {
          orderInfo.value = userInfoDataParsed.orderInfo;
        }
      }
    };

    const getRoomsFromDB = async (email: string) => {
      if (allRoom.value.length) return;
      allRoom.value = await db.getRooms(email);
      getUserInfoToLocal();
    };

    const deleteRoomToDB = async (room: TTypeCurrentRoom) => {
      await db.deleteRoom(room);
    };

    const editRoomToDB = async (room: TTypeCurrentRoom) => {
      await db.saveRoom({
        id: room?.id,
        imagePlan: {...room?.imagePlan},
        measureRoom: {...room?.measureRoom},
        styleRoom: {...room?.styleRoom},
        typeRoom: {...room?.typeRoom},
        countBed: {...room?.countBed},
      });
    };

    const createOrderFromBack = async () => {
      createOrderLoader.value = true;
      const formData = new FormData();
      formData.append('name', userInfo.value.name);
      formData.append('surname', userInfo.value.surname);
      formData.append('region', userInfo.value.region);
      formData.append('phone', userInfo.value.phone);
      formData.append(
        'isDesignAllroom',
        `${isDesignAllRoomCheckbox.value}`,
      );
      allRoom.value.forEach((room, index) => {
        const roomForBack = {
          ...room,
          imagePlan: {file: room?.imagePlan?.file},
        };

        formData.append(
          `room${index + 1}`,
          JSON.stringify(roomForBack),
        );
        formData.append(`room${index + 1}`, room?.imagePlan?.file);
      });
      const response = await api.post('/design/create', formData, {
        headers: {
          Authorization: `Bearer ${useCookie('df').value}`,
        },
      });
      createOrderLoader.value = false;
      if (response.status < 205) {
        orderInfo.value = response.data.payload;
        deleteUserInfoFieldToLocale('orderInfo');
        setUserInfoNewFieldToLocale('orderInfo', orderInfo.value);
        router.push(`/${locale.value}/design-studio/pay`);
      }
    };

    const alfaPayment = async () => {
      const response = await apiPayment.post(
        '/payment/create_order/design',
        {order: orderInfo.value.order},
        {
          headers: {
            Authorization: `Bearer ${useCookie('df').value}`,
          },
        },
      );

      if (response.status <= 205) location.href = response.data;
    };

    const clearAllDataAfterSuccessAdded = (email: string) => {
      localStorage.removeItem(`ds-userInfo-${email || user.email}`);
      db.clearDateBase(email);
      allRoom.value = [];
      currentRoom.value = {};
      userInfo.value = {name: '', region: '', surname: '', phone: ''};
    };

    const getReviews = (staticUrl: string) => {
      return [
        {
          id: 1,
          img: `${staticUrl}/images/design-studio/ds-review-1.webp`,
          title: 'DStudioReviews1Title',
          imgBeforeTitle: `${staticUrl}/images/design-studio/ds-review-1-people.webp`,
          stars: 'DStudioReviews1Grade',
          subtitle: 'DStudioReviews1Description',
        },
        {
          id: 2,
          img: `${staticUrl}/images/design-studio/ds-review-2.webp`,
          title: 'DStudioReviews2Title',
          imgBeforeTitle: `${staticUrl}/images/design-studio/ds-review-2-people.webp`,
          stars: 'DStudioReviews2Grade',
          subtitle: 'DStudioReviews2Description',
        },
        {
          id: 3,
          img: `${staticUrl}/images/design-studio/ds-review-3.webp`,
          title: 'DStudioReviews3Title',
          imgBeforeTitle: `${staticUrl}/images/design-studio/ds-review-3-people.webp`,
          stars: 'DStudioReviews3Grade',
          subtitle: 'DStudioReviews3Description',
        },
        {
          id: 4,
          img: `${staticUrl}/images/design-studio/ds-review-4.webp`,
          title: 'DStudioReviews4Title',
          imgBeforeTitle: `${staticUrl}/images/design-studio/ds-review-4-people.webp`,
          stars: 'DStudioReviews4Grade',
          subtitle: 'DStudioReviews4Description',
        },
      ]
    }

    return {
      btnCreateHandlerForLanding,
      addedNewRoom,
      deleteCurrentRoom,
      changeIsNeedFieldForAddNewRoom,
      addTypeForCurrentRoom,
      addMeasureForCurrentRoom,
      addCountBedForCurrentRoom,
      addStyleRoomForCurrentRoom,
      deleteRoomFromAllList,
      editCurrentRoomFromAllList,
      cancelEditFromAllRoomList,
      applyChangesFromEditAllRoomList,
      addedNewRoomFromPreviewRoom,
      addImagePlanForCurrentRoom,
      getRoomsFromDB,
      createOrderFromBack,
      addUserInfoToLocal,
      getUserInfoToLocal,
      clearAllDataAfterSuccessAdded,
      alfaPayment,
      getReviews,
      userInfo,
      totalPriceDS,
      allRoom,
      currentRoom,
      isNeedFieldForAddNewRoom,
      isEditMode,
      isDeisgnAllRoomCheckboxVisible,
      isDesignAllRoomCheckbox,
      isAgreeOfferCheckBox,
      createOrderLoader,
      orderInfo,
    };
  },
);
