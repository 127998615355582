const DB_NAME = 'studio-design';
const STORAGE_NAME = 'rooms';
const DB_VERSION = 1;
let DB;
let prefix;
export default {
  async getDb() {
    return new Promise((resolve, reject) => {
      if (DB) {
        return resolve(DB);
      }
      const indexDB =
        window.indexedDB ||
        window.mozIndexedDB ||
        window.webkitIndexedDB ||
        window.msIndexedDB ||
        window.shimIndexedDB;
      const request = indexDB.open(
        `${DB_NAME}-${prefix}`,
        DB_VERSION,
      );
      request.onerror = (e) => {
        console.log('Error opening db', e);
        // eslint-disable-next-line prefer-promise-reject-errors
        reject('Error');
      };
      request.onsuccess = (e) => {
        DB = e.target.result;
        resolve(DB);
      };
      request.onupgradeneeded = (e) => {
        let db = e.target.result;
        db.createObjectStore(STORAGE_NAME, {
          autoIncrement: true,
          keyPath: 'id',
        });
      };
    });
  },
  async deleteRoom(room) {
    const db = await this.getDb(prefix);
    return new Promise((resolve) => {
      const trans = db.transaction([STORAGE_NAME], 'readwrite');
      trans.oncomplete = () => {
        resolve();
      };
      const store = trans.objectStore(STORAGE_NAME);
      store.delete(room.id);
    });
  },
  async getRooms(email) {
    prefix = email;
    let db = await this.getDb();
    return new Promise((resolve) => {
      let trans = db.transaction([STORAGE_NAME], 'readonly');
      trans.oncomplete = () => {
        resolve(rooms);
      };
      const store = trans.objectStore(STORAGE_NAME);
      const rooms = [];
      store.openCursor().onsuccess = (e) => {
        const cursor = e.target.result;
        if (cursor) {
          rooms.push(cursor.value);
          cursor.continue();
        }
      };
    });
  },
  async saveRoom(room) {
    let db = await this.getDb(prefix);
    return new Promise((resolve) => {
      let trans = db.transaction([STORAGE_NAME], 'readwrite');
      trans.oncomplete = () => {
        resolve();
      };
      let store = trans.objectStore(STORAGE_NAME);
      store.put(room);
    });
  },
  async clearDateBase(email) {
    prefix = email;
    const db = await this.getDb();
    return new Promise((resolve) => {
      const trans = db.transaction([STORAGE_NAME], 'readwrite');
      trans.oncomplete = () => {
        resolve();
      };
      const store = trans.objectStore(STORAGE_NAME);
      store.clear();
    });
  },
};
